// import { Button } from '#src/components/ui/button';
// import { Card } from '#src/components/ui/card';

// function App() {
//   return (
//     <Card>
//       <h1 className="text-3xl font-bold underline">
//         Hello world!
//       </h1>
//       <Button>Button</Button>
//     </Card>
//   );
// }

// export default App;

import * as React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
// import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { Dashboard } from './pages/Template1';
import HeroSectionTemplate from './pages/HeroSectionTemplate';
import FeatureSectionTemplate from './pages/FeatureSectionTemplate';
import TestimonialsGpt from './pages/TestomonialsGpt';
import TestimonialsClaude from './pages/TestomonialsClaude';
import GlobleTestomonial from './pages/GlobeTestomonial';
import TeamSection from './pages/TeamSectionCluade';
import Template2 from './pages/Template2';
import Template3 from './pages/Template3';
import Template4shadCn from './pages/Template4shadCn';


// import './styles/tailwind.css';

const App: React.FC = () => {
  return (
    <div className='relative flex min-h-screen flex-col bg-background'>
      {/* <Header />
      <main className='flex-1'>
        <Hero />
        <Services />
        <Testimonials />
        <Contact />
        <Footer />
      </main> */}
      {/* <Dashboard /> */}
      {/* <HeroSectionTemplate /> */}
      {/* <FeatureSectionTemplate /> */}
      {/* <TestimonialsGpt /> */}
      {/* <TestimonialsClaude /> */}

      {/* <GlobleTestomonial /> */}
      {/* <Template2 /> */}
      {/* <TeamSection /> */}
      {/* <Template3 /> */}
      <Template4shadCn />
    </div>
  );
};


export default App;
